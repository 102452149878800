import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
    // 首页
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    // 客服端
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/serviceView.vue'),

        children: [
          //   {
          //       path: '',
          //       component: () => import('../components/Service/ControlView.vue'),
          // },
          {
            path: '',
            component: () => import('../components/Service/ControlView1.vue'),
        },
            {
                path: 'setting',
                component: () => import('../components/Service/SettingView.vue'),
            },
            {
                path: 'clues',
                component: () => import('../components/Service/CluesView.vue'),
            },
            {
                path: 'Addnewplan',
                component: () => import('../components/Service/components/Addnewplan.vue'),
            },
            {
                path: 'pause',
                component: () => import('../components/Service/PauseView.vue'),
            },
            {
                path: 'salesTable',
                component: () => import('../components/Service/SalesTableView.vue'),
            },
            {
                path: 'toDo',
                component: () => import('../components/Service/ToDoView.vue'),
            },
            // 待办记录详情
            {
                path: 'toDoDetail',
                component: () => import('../components/Service/components/toDoDetailView.vue'),
            },
            // 待回款
            {
                path: 'stayCollection',
                component: () => import('../components/Service/stayCollectionView.vue'),
            },
            {
                path: 'check',
                component: () => import('../components/Service/CheckView.vue'),
            },
            {
                path: 'urgent',
                component: () => import('../components/Service/UrgentView.vue'),
            },
            // 抽查记录
            {
                path: 'spotCheck',
                component: () => import('../components/spotCheck/spotCheckView.vue'),
            },
             // 抽查记录详情
             {
                path: 'spotCheckDetail',
                name: "spotCheckDetail",
                component: () => import('../components/spotCheck/spotCheckDetailView.vue'),
            },
            {
                path: 'shelve',
                component: () => import('../components/Service/ShelveView.vue'),
            },
            {
                path: 'customershelve',
                component: () => import('../components/Service/CustomerShelveView.vue'),
            },
            {
                path: 'customerShelveDetail',
                component: () => import('../components/ShelveElement/CustomerShelveDetailView.vue'),
            },
            {
                path: 'checkdetail',
                component: () => import('../components/CheckElement/CheckDetailView.vue'),
            },
            {
                path: 'management',
                name: 'management',
                component: () => import('../components/ManagementElement/ManagementView.vue'),
            },
            {
                path: 'shelvedetail',
                name: 'shelvedetail',
                component: () => import('../components/ShelveElement/ShelveDatailView.vue'),
            },
            {
                path: 'casedetail',
                name: 'casedetail',
                component: () => import('../components/RoundElement/CaseDatailView.vue'),
            },
            {
                path: 'detail',
                name: 'detail',
                component: () => import('../components/UrgentElement/DetailView.vue'),
            },
            // 调解员客户管理
            {
                path: 'lawtotal',
                component: () => import('../components/Service/LawtotalView.vue'),
            },
            // 服务助理客户管理
            {
                path: 'assistant',
                component: () => import('../components/Service/AssistantView.vue'),
            },
            // 备注跟进
            {
                path: 'remarksFollow',
                name: 'remarksFollow',
                component: () => import('../components/Service/remarksFollow.vue'),
            },
            // 调解员客户查看
            {
                path: 'lawtotaldetail',
                name: 'lawtotaldetail',
                component: () => import('../components/Service/LawTotalDetailView.vue'),
            },
            // 案件管理
            {
                path: 'case',
                component: () => import('../components/Service/CaseView.vue'),
            },

            // 播报设置-播报目标填报
            {
                path: "target",
                component: () => import("../components/BroadcastElement/Target.vue")
            },
            // 播报设置-播报口号设置
            {
                path: "slogan",
                component: () => import("../components/BroadcastElement/Slogan.vue")
            },
            // 播报设置-团队人力填报
            {
                path: "manpower",
                component: () => import("../components/BroadcastElement/Manpower.vue")
          },
          {
            path: "hdvue",
            component: () => import("../components/HDvue.vue")
        },
            // 短信管理 === 发送记录
            {
                path: 'sendMessage',
                component: () => import('../components/MessageElement/SendMessageView.vue'),
            },
            // 短信管理 === 新建发送
            {
                path: 'creatMessage',
                component: () => import('../components/MessageElement/CreatMessageView.vue'),
            },// 短信记录详情
            {
                path: 'msgDetail',
                name: "msgDetail",
                component: () => import('../components/MessageElement/components/msgDetailView.vue'),
            },

            // 销售抢量活动
            {
                path: "seizeQuantityLog",
                name: "seizeQuantityLog",
                component: () => import("../components/SeizeQuantity/Log.vue")
            },
            {
                path: "notice_list",
                name: "notice_list",
                component: () => import("../components/NoticeList/list.vue"),
            },
            {
                path: "clueAnalysis",
                name: "clueAnalysis",
                component: () => import("../components/DataReport/clueAnalysis/clueAnalysis.vue"),
            },
            {
                path: "Salestarget",
                name: "Salestarget",
                component: () => import("../components/DataReport/Salestarget.vue")
            },
        ]
    },
    {
        path: '/404',
        name: '/404',
        component: () => import('../views/NotFound.vue')
    },
    {
        path: '/:pathMatch(.*)',
        redirect: "/404"
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
