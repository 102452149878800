import axios from 'axios'

let URL = ""
let str = location.href
if (str.indexOf("test") == -1) {
    if (str.indexOf("192.168") != -1) {
        URL = "http://testzyxccrm.zhiyunjishu.cn"
        // URL = "http://www.szmccrm.com"
    } else if (str.indexOf("localhost") != -1) {
        URL = "http://testzyxccrm.zhiyunjishu.cn"
    } else {
        URL = "http://szmccrm.yuluojishu.com"
    }
} else {
    URL = "http://testzyxccrm.zhiyunjishu.cn"
}

const request = axios.create({
    baseURL: URL,
    timeout: 8000,
    // headers: { 'Content-type': 'application/json' }
    // headers: {
    // "Authorization": 'Bearer ' + token,
    // 'Content-Type': '"application/json;charset:utf-8;"'
    // }
})

// 提交
request.interceptors.request.use(
    config => {
        let token = window.localStorage.getItem("token")
        config.headers = { "Laytp-Admin-Token": token }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 请求
request.interceptors.response.use(
    response => {
        const res = response.data
        return Promise.resolve(res)
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数
    },
    error => {

        return Promise.reject(error)
    }
)
export default request
