import { createStore } from 'vuex'
// 引入持久化
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    // 存储数据
    state: {
        spotCheckInfo:{},
        popObj: {},
        followObj: {},
        caseInfo: {},
        caseData: {
            data1: 0,
            data2: 0,
            data3: 0,
            data4: 0,
            data5: 0,
            data6: 0,
            data7: 0,
            data1_1: 0,
            data1_2: 0,
            data1_3: 0,
            data1_4: 0,
            data1_5: 0,
            current_handle_num: 0,
            urgent_total_num: 0,
            urgent_warning_num: 0,
            new_data1: 0,
            new_data2: 0,
            new_data3: 0,
            old_data1: 0,
            old_data2: 0,
            old_data3: 0,
        },
        navbool: true,
        // 用户信息
        userInfo: {},
        // 用户线索信息
        lineInfo: {},
        testnum: 0,
        searchInfo: {},
        // 公海搜索
        seaInfo: {},
        // 路由
        routerPath: "",
        routerBool: {},
        // 线索列表导航数据
        listData: {
            data1: 0,
            data2: 0,
            data3: 0,
            data4: 0
        },
        // 消息提示
        messageList: []
    },
    // 存储复杂数据
    getters: {
    },
    // 存储函数方法
    mutations: {
        setSpotCheckInfo(state,e){
            state.spotCheckInfo=e
        },
        setPopObj (state, popObj) {
            state.popObj = popObj
        },
        setFollowObj (state, followObj) {
            state.followObj = followObj
        },
        setCaseInfo (state, caseInfo) {
            state.caseInfo = caseInfo
        },
        setCaseData (state, caseData) {
            state.caseData.data1 = caseData.data1
            state.caseData.data2 = caseData.data2
            state.caseData.data3 = caseData.data3
            state.caseData.data4 = caseData.data4
            state.caseData.data5 = caseData.data5
            state.caseData.data6 = caseData.data6
            state.caseData.data7 = caseData.data7
            state.caseData.data1_1 = caseData.data1_1
            state.caseData.data1_2 = caseData.data1_2
            state.caseData.data1_3 = caseData.data1_3
            state.caseData.data1_4 = caseData.data1_4
            state.caseData.data1_5 = caseData.data1_5
            state.caseData.current_handle_num = caseData.current_handle_num
            state.caseData.urgent_total_num = caseData.urgent_total_num
            state.caseData.urgent_warning_num = caseData.urgent_warning_num
            state.caseData.new_data1 = caseData.new_data1
            state.caseData.new_data2 = caseData.new_data2
            state.caseData.new_data3 = caseData.new_data3
            state.caseData.old_data1 = caseData.old_data1
            state.caseData.old_data2 = caseData.old_data2
            state.caseData.old_data3 = caseData.old_data3

            state.caseData.cause_num_total = caseData.cause_num_total
            state.caseData.suspend_total_num = caseData.suspend_total_num
            state.caseData.windup_audit_total = caseData.windup_audit_total
            state.caseData.overdue_num = caseData.overdue_num
            state.caseData.not_overdue_num = caseData.not_overdue_num
            state.caseData.windup_audit_in = caseData.windup_audit_in
            state.caseData.windup_audit_reject = caseData.windup_audit_reject
            state.caseData.suspend_customer_num=caseData.suspend_customer_num
            state.caseData.suspend_legal_num=caseData.suspend_legal_num
            state.caseData.suspend_auto_num=caseData.suspend_auto_num
        },
        setNavbool (state) {
            state.navbool = !state.navbool
        },
        // 存储消息提示
        setMessageList (state, messageList) {
            state.messageList = messageList
        },
        // 存储用户信息
        loginSuccess (state, userInfo) {
            state.userInfo = userInfo
        },
        //退出登录清除用户信息
        loginOut (state) {
            state.userInfo = {}
        },
        // 存储用户线索
        setUserId (state, lineInfo) {
            state.lineInfo = lineInfo
        },
        // 搜索调用
        searcher (state, searchInfo) {
            state.searchInfo = searchInfo
        },
        // 重置调用
        reseter (state) {
            state.searchInfo = {}
        },
        // 公海搜索调用
        seaSearch (state, seaInfo) {
            state.seaInfo = seaInfo
        },
        // 路由跳转打印
        setRouter (state, routerPath) {
            state.routerPath = routerPath
        },
        // 路由布尔值
        setRouterBool (state, routerBool) {
            state.routerBool = routerBool
        },
        // 存储线索列表导航数据
        setlistData (state, listData) {
            state.listData.data1 = listData.data1
            state.listData.data2 = listData.data2
            state.listData.data3 = listData.data3
            state.listData.data4 = listData.data4
        },

    },
    // 异步调用函数
    actions: {
    },
    // 模块
    modules: {
    },

    //持久化数据
    plugins: [
        createPersistedState({
            // 存储方式：localStorage、sessionStorage、cookies
            storage: window.sessionStorage,
            // 存储的 key 的key值
            key: "store",
            render (state) {
                // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
                return { ...state };
            }
        })
    ]
})
