import { createApp } from 'vue'
import App from './App.vue'
import iComet from './components/middle/icomet'
import router from './router'
import store from './store'
import saveAs from 'file-saver'
import VueCookies from "vue-cookies"
import smart from 'address-smart-parse'
import installElementPlus from './plugins/element'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import http from "./utils/http";
import day from "./utils/day";
import VueQrcode from 'vue-qrcode'




// 图标
const app = createApp(App)
app.component('vue-qrcode', VueQrcode)

import dir from "./directive/index";
import EventBus from './eventBus/myEventBus'
// 或者：import EventBus from 'mitt'
const $bus = new EventBus()

// ② 挂载
// 1.使用provide提供
app.provide('$bus', $bus)
// 2.挂载到this上
app.config.globalProperties.$bus = $bus
app.directive("myOneClick", dir.my_one_click);
app.directive("adaptive", dir.adaptive);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import { ElCollapseTransition } from 'element-plus'
import 'element-plus/lib/theme-chalk/base.css'
app.component(ElCollapseTransition.name, ElCollapseTransition)
installElementPlus(app)

// const baseURL = '/api'
const baseURL = ''   

//配置全局axios
axios.defaults.baseURL = baseURL //配置前缀url
axios.defaults.withCredentials = true //携带cookie
app.config.globalProperties.$axios = axios //添加到vue实例
app.config.globalProperties.iComet = iComet//添加到vue实例
app.config.globalProperties.$cookies = VueCookies //添加到vue实例
app.config.globalProperties.$smart = smart //添加到vue实例  
app.config.globalProperties.$saveAs = saveAs //添加到vue实例
app.config.globalProperties.$day = day; //添加到vue实例
app.provide('http', { http })
app.use(store).use(router).mixin({
    data () {
        return { baseURL }
    }
}).mount('#app')


